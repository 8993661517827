import { render, staticRenderFns } from "./CaseLevel.vue?vue&type=template&id=1a18a5af&scoped=true&"
import script from "./CaseLevel.vue?vue&type=script&lang=js&"
export * from "./CaseLevel.vue?vue&type=script&lang=js&"
import style0 from "./CaseLevel.vue?vue&type=style&index=0&id=1a18a5af&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a18a5af",
  null
  
)

export default component.exports