<template>
  <div>
    <h2>Merge Internal Case Manager</h2>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="State"
          label-for="state"
        >
          <v-select
            id="state"
            v-model="states"
            label="title"
            :options="StatesList"
            placeholder=""
            name="state"
            multiple
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-row>
          <b-col>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <v-select
                id="role"
                v-model="role"
                label="title"
                :options="roleList"
                placeholder=""
                name="role"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="User to Assign"
              label-for="user"
            >
              <v-select
                id="user"
                v-model="user"
                label="title"
                :options="userList"
                placeholder=""
                name="user"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="3">
        <b-row class="d-flex justify-content-center">
          <b-button
            type="button"
            variant="primary"
            class="mb-1 mt-1 width-100"
            @click="mergeInternal()"
          >
            <span>Ok</span>
          </b-button>
        </b-row>

        <b-row class="d-flex justify-content-center">
          <b-button
            type="button"
            variant="primary"
            class="mb-1 mt-1 width-100"
            @click="cancelMerge()"
          >
            <span>Cancel</span>
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-if="user && role && states.length"
      id="modal-merge-user"
      ok-variant="danger"
      ok-title="Ok"
      cancel-title="Cancel"
      modal-class="modal-danger"
      hide-header-close
      centered
      title="Merge Internal Case Manager"
      @ok="confirmMerge()"
    >
      <div>
        User {{ user.name }} will be assigned as {{ role.title }}  to all open cases in {{ states | joinStates }} where there already another user assigned.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BFormGroup,
  BButton,
  BModal, BRow, BCol,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import APIService from "@core/utils/APIService";
import vSelect from "vue-select";

const apiService = new APIService();

export default {
  components: {
    BFormGroup,
    BBreadcrumb,
    BModal,
    BButton,
    BRow,
    BCol,
    vSelect,
  },
  filters: {
    joinStates(val) {
      return val.join(", ")
    }
  },
  data() {
    return {
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
      breadcrumbItems: [
        {
          text: "Admin",
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Merge Internal Case Manager",
          active: true,
        },
      ],
      states: [],
      roleList: [
        {
          title: "OperationsManager",
          value: 0,
        },
        {
          title: "ResearchManager",
          value: 1,
        },
        {
          title: "SIUManager",
          value: 2,
        },
        {
          title: "USPManager",
          value: 3,
        },
        {
          title: "VendorManager",
          value: 4,
        },
      ],
      userList: [],
      role: null,
      user: null,
    };
  },
  async created() {
    try {
      this.loadUsers();
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },

    loadUsers() {
      apiService.get('users/user')
          .then(res => {
            this.userList = res.data.Users.map(i => ({
              title: i.LastName + ", " + i.FirstName + " - " + i.EMail,
              value: i.UserAccountID,
              name: i.LastName + ", " + i.FirstName,
            }))
          })
          .catch(error => error)
    },

    confirmMerge() {
      const postData = {
        "states": this.states,
        "role": this.role.value,
        "userId": this.user.value
      }
      apiService
          .post("case-manager/merge-internal", postData)
          .then(res => {
            if (res && res.data && res.data.updatedCaseCount) {
              this.showToast("success", "top-center", 4000, res.data.updatedCaseCount + " cases were updated");
            }
          });
    },

    cancelMerge() {
      this.role = null;
      this.user = null;
      this.states = [];
    },

    mergeInternal() {
      this.$bvModal.show("modal-merge-user");
    },
  },
};
</script>

<style>
</style>
