<template>
  <b-row class="pt-1">
    <b-col>
      <b-row class="pb-1">
        <b-col>
          <b-form-checkbox
            id="previousSurveillanceConducted"
            v-model="HasPreviousSurveillanceConducted"
            name="requireAuthentication"
            :disabled="readOnly"
          >
            Previous Surveillance Conducted
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="HasPreviousSurveillanceConducted">
        <b-col md="3">
          <p>Conducted By</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedConductedBy}"
          >
            <textarea
              v-model="PreviousSurveillanceConductedBy"
              class="expandable-textarea"
              :class="[isExpandedConductedBy ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedConductedBy = !isExpandedConductedBy">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedConductedBy ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="HasPreviousSurveillanceConducted"
        class="pt-1 pb-1"
      >
        <b-col md="3">
          <p>Previous Surveillance Results</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedResults}"
          >
            <textarea
              v-model="PreviousSurveillanceResults"
              class="expandable-textarea"
              :class="[isExpandedResults ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedResults = !isExpandedResults">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedResults ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <p>Reason for Assignment</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedSurveillance}"
          >
            <textarea
              v-model="ReasonForSurveillance"
              class="expandable-textarea"
              :class="[isExpandedSurveillance ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedSurveillance = !isExpandedSurveillance">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedSurveillance ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-1 pb-1" v-if="PriorNotes && PriorNotes.length">
        <b-col md="3">
          <p>Prior Notes</p>
        </b-col>
        <b-col>
          <b-row
            v-for="(priorNote, index) of PriorNotes"
            :key="index"
          >
            <b-col>
              <div>
                {{ priorNote.CaseCreatedDate | dateFormat }}
              </div>
              <textarea
                ref="textarea"
                v-model="priorNote.Notes"
                class="expandable-textarea"
                style="min-height: 5rem"
                :readonly="true"
                @input="adjustTextareaHeight($event)"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="pt-1 pb-1">
        <b-col md="3">
          <p>Notes Regarding This Assignment</p>
        </b-col>
        <b-col>
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpandedAssignment}"
          >
            <textarea
              v-model="NotesRegardingThisAssignment"
              class="expandable-textarea"
              :class="[isExpandedAssignment ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="readOnly"
            />
            <div @click="isExpandedAssignment = !isExpandedAssignment">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpandedAssignment ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCol, BFormCheckbox, BRow} from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  filters: {
    dateFormat(val) {
      if (!val) return null
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      return date.toLocaleDateString("en-US", options);
    },
  },
  data() {
    return {
      SurveillanceConducted: null,
      HasPreviousSurveillanceConducted: false,
      PreviousSurveillanceConductedBy: "",
      PreviousSurveillanceResults: "",
      ReasonForSurveillance: "",
      NotesRegardingThisAssignment: "",
      PriorNotes: [],

      isExpandedConductedBy: false,
      isExpandedResults: false,
      isExpandedSurveillance: false,
      isExpandedAssignment: true,

      readOnly: false,
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "cases/getSubject",
      getIsReadOnly: "cases/getIsReadOnly",
      getAllCaseTabOpen: "cases/getAllCaseTabOpen",
    }),
    getSubjectData() {
      return this.getSubject
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getAllCaseTabOpen: {
      immediate: true,
      handler(value) {
        const val = !!value
        this.isExpandedConductedBy = val
        this.isExpandedResults = val
        this.isExpandedSurveillance = val
      }
    },
    getSubjectData(val) {
      this.fillSubject(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    HasPreviousSurveillanceConducted(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, HasPreviousSurveillanceConducted: val}},
    PreviousSurveillanceResults(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, PreviousSurveillanceResults: val}},

    PreviousSurveillanceConductedBy(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, PreviousSurveillanceConductedBy: val}},
    ReasonForSurveillance(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, ReasonForSurveillance: val}},
    NotesRegardingThisAssignment(val) {this.SurveillanceConducted = {...this.SurveillanceConducted, NotesRegardingThisAssignment: val}},

    SurveillanceConducted(val) {
      this.$emit("changedSurveillanceConducted", val);
    },
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillSubject(this.getSubject);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    fillSubject(val) {
      this.HasPreviousSurveillanceConducted = val.HasPreviousSurveillanceConducted || null;
      this.PreviousSurveillanceResults = val.PreviousSurveillanceResults || null;
      this.ReasonForSurveillance = val.ReasonForSurveillance || null;
      this.NotesRegardingThisAssignment = val.NotesRegardingThisAssignment || null;
      this.PreviousSurveillanceConductedBy = val.PreviousSurveillanceConductedBy || null;
      this.PriorNotes = val.PriorNotes || [];
      this.$nextTick(() => {
        this.adjustTextareaHeight({ target: this.$refs.textarea });
      });
    },

    adjustTextareaHeight(event) {
      const textarea = event.target;
      if (textarea) {
        textarea.map((item, index) => {
          textarea[index].style.height = 'auto';
          textarea[index].style.height = `${textarea[index].scrollHeight}px`;
        })
      }
    },
  }
}
</script>

<style scoped>
  p {
    font-size: .857rem;
  }

</style>