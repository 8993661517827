<template>
  <b-row class="p-1">
    <b-col>
      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  label="First Name"
                  label-for="firstName"
                  class="required-data"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="firstName"
                    v-model="FirstName"
                    placeholder=""
                    name="firstName"
                    :readonly="readOnly"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Middle Name"
                label-for="middleName"
              >
                <b-form-input
                  id="middleName"
                  v-model="MiddleName"
                  placeholder=""
                  name="middleName"
                  :readonly="readOnly"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  class="required-data"
                  :state="errors.length > 0 ? false : null"
                  label="Last Name"
                  label-for="lastName"
                >
                  <b-form-input
                    id="lastName"
                    v-model="LastName"
                    placeholder=""
                    name="lastName"
                    :readonly="readOnly"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Alias"
                label-for="alias"
              >
                <b-form-input
                  id="alias"
                  v-model="Alias"
                  placeholder=""
                  name="alias"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="SSN"
                label-for="ssn"
              >
                <b-input-group>
                  <b-form-input
                    v-if="!getCase.CaseID || !readOnly"
                    id="ssn"
                    v-model="SocialSecurityNo"
                    v-mask="'###-##-####'"
                    type="text"
                    placeholder=""
                    name="ssn"
                    :readonly="readOnly"
                    @blur.native="handleSocialSecurityNo"
                    @keypress.enter="handleSocialSecurityNo"
                  />
                  <b-form-input
                    v-else
                    id="ssnInput"
                    :value="fullSocialSecurityNoIce ? fullSocialSecurityNo : SocialSecurityNo"
                    name="ssnInput"
                    :readonly="true"
                  />
                  <div
                    v-if="(!!getCase.CaseID && UserType !== 'Investigator') || (UserType === 'Investigator' && getCurrentScopes.indexOf('st2.viewssnanddob') !== -1 )"
                    class="ice-icon"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      :icon="fullSocialSecurityNoIce? 'EyeOffIcon' : 'EyeIcon'"
                      @click="toggleVisibilityFields('fullSocialSecurityNoIce')"
                    />
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="DOB"
                label-for="dob"
              >
                <b-input-group>
                  <b-form-input
                    v-if="!getCase.CaseID || !readOnly"
                    id="dob"
                    v-model="DateOfBirth"
                    type="date"
                    min="1900-01-01"
                    max="2099-12-31"
                    placeholder=""
                    name="dob"
                    :readonly="readOnly"
                    @blur="isValidDateOfBirth()"
                  />
                  <b-form-input
                    v-else
                    id="dobInput"
                    :value="fullDateOfBirthIce ? fullDateOfBirth : DOBForInput"
                    :readonly="true"
                    name="dobInput"
                  />

                  <div
                    v-if="(!!getCase.CaseID && UserType !== 'Investigator') || (UserType === 'Investigator' && getCurrentScopes.indexOf('st2.viewssnanddob') !== -1 )"
                    class="ice-icon"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      :icon="fullDateOfBirthIce? 'EyeOffIcon' : 'EyeIcon'"
                      @click="toggleVisibilityFields('fullDateOfBirthIce')"
                    />
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Driver’s License No."
                label-for="driversLicenseNo"
              >
                <b-input-group>
                  <b-form-input
                    v-if="!getCase.CaseID || !readOnly"
                    id="driversLicenseNo"
                    v-model="DriversLicenseNo"
                    placeholder=""
                    name="driversLicenseNo"
                    :readonly="readOnly"
                  />
                  <b-form-input
                    v-else
                    id="dlnInput"
                    :value="fullDriversLicenseNoIce ? fullDriversLicenseNo : DriversLicenseNo"
                    :readonly="true"
                    name="dlnInput"
                  />
                  <div
                    v-if="(!!getCase.CaseID && UserType !== 'Investigator') || (UserType === 'Investigator' && getCurrentScopes.indexOf('st2.viewssnanddob') !== -1 )"
                    class="ice-icon"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      :icon="fullDriversLicenseNoIce? 'EyeOffIcon' : 'EyeIcon'"
                      @click="toggleVisibilityFields('fullDriversLicenseNoIce')"
                    />
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="State"
                label-for="state"
              >
                <v-select
                  id="state"
                  v-model="DriversLicenseState"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="StatesList"
                  placeholder=""
                  name="state"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-form-group
            label="Subject Photo"
            label-for="subjectImage"
          >
            <DropSubjectImage
              :is-read-only="readOnly"
              @reloadData="reloadData"
              @uploadSubjectImage="uploadSubjectImage"
            />
            <div
              v-if="Image && Image.length"
            >
              <b-form-checkbox
                id="contract"
                v-model="IsPhotoVerified"
                name="vendingService"
                :disabled="readOnly"
              >
                Photo Verified
              </b-form-checkbox>
              <b-form-input
                id="source"
                v-model="PhotoSource"
                placeholder="Source"
                name="source"
                :readonly="readOnly"
              />
            </div>
            <b-button
              v-if="showViewAllButton()"
              type="button"
              variant="primary"
              class="mb-1 mt-1"
              @click="viewAllImages()"
            >
              <span>View All Images</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="pl-0"
        >
          <b-form-group
            label="Phone 1"
            label-for="phone1"
          >
            <b-form-input
              id="phone1"
              v-model="Phone1"
              v-mask="'(###) ###-####'"
              placeholder=""
              name="phone1"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Type"
            label-for="phoneType1"
          >
            <v-select
              id="phoneType1"
              v-model="Phone1Type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="phoneType"
              placeholder=""
              name="phoneType1"
              :disabled="readOnly"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="pl-0"
        >
          <b-form-group
            label="Phone 2"
            label-for="phone2"
          >
            <b-form-input
              id="phone2"
              v-model="Phone2"
              v-mask="'(###) ###-####'"
              placeholder=""
              name="phone2"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Type"
            label-for="phoneType2"
          >
            <v-select
              id="phoneType2"
              v-model="Phone2Type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="phoneType"
              placeholder=""
              name="phoneType2"
              :disabled="readOnly"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>There is a match for this SSN under claim number<br>[<span v-text="SocialSecurityNo" />]</strong>
          </h2>
          <h5 class="text-center">
            Do you wish to review this case?
          </h5>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="chancelCase()"
            >
              Ignore
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="reviewCase()"
            >
              View Case
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-all-images"
      modal-class="modal-primary"
      ok-variant="primary"
      ok-title="Cancel"
      ok-only
      centered
      hide-header-close
    >
      <div class="images-wrapper">
        <div
          v-for="(item, index) in RelatedImages"
          :key="index"
        >
          <div class="image-wrapper">
            <img class="img-block" :src="item.Image">
            <b-form-checkbox
              v-if="item.IsPhotoVerified"
              class="verified"
              v-model="item.IsPhotoVerified"
              name="verified"
              :disabled="true"
            >
              <div class="tooltip-source">{{ item.Source }}</div>
            </b-form-checkbox>
            <div class="date-field">{{ item.CaseCreatedDate | dateFormat }}</div>
            <div class="link-title">{{ item.InternalNumber }}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import DropSubjectImage from "@/views/cases/components/DropSubjectImage";
import { mapGetters } from "vuex";
import APIService from "@core/utils/APIService";
import { ValidationProvider } from "vee-validate";
import { tryGetDate } from '@core/utils/filter';
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    DropSubjectImage,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
  },
  filters: {
    dateFormat(val) {
      if (val) return val.substr(0, 10);
      return val
    },
  },
  data() {
    return {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Alias: "",
      SocialSecurityNo: "",
      DateOfBirth: "",
      DOBForInput: "",
      DriversLicenseNo: "",
      DriversLicenseState: "",
      Subject: {},
      matchCaseID: "",
      RelatedImages: [],

      PhotoSource: "",
      IsPhotoVerified: false,

      fullDateOfBirth: "",
      fullDriversLicenseNo: "",
      fullSocialSecurityNo: "",

      fullDateOfBirthIce: false,
      fullDriversLicenseNoIce: false,
      fullSocialSecurityNoIce: false,

      Phone1: "",
      Phone1Type: "",
      Phone2: "",
      Phone2Type: "",

      readOnly: false,
      modalShow: false,

      phoneType: ["Work", "Cell", "Home"],
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],

      visibilityFields: false,
      UserType: "",

      tryGetDate,
    }
  },
  computed: {
    ...mapGetters({
      getSubject: "cases/getSubject",
      getCase: "cases/getCase",
      getIsReadOnly: "cases/getIsReadOnly",
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    passwordToggleIcon() {
      return this.visibilityFields ? 'EyeOffIcon' : 'EyeIcon';
    },
    getSubjectData() {
      return this.getSubject
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getSubjectData(val) {
      this.fillSubject(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    FirstName(val) {this.Subject = {...this.Subject, FirstName: val}},
    MiddleName(val) {this.Subject = {...this.Subject, MiddleName: val}},
    LastName(val) {this.Subject = {...this.Subject, LastName: val}},
    Alias(val) {this.Subject = {...this.Subject, Alias: val}},
    SocialSecurityNo(val) {this.Subject = {...this.Subject, SocialSecurityNo: val}},
    DateOfBirth(val) {this.Subject = {...this.Subject, DateOfBirth: val}},
    DOBForInput(val) {this.Subject = {...this.Subject, DOBForInput: val}},
    DriversLicenseNo(val) {this.Subject = {...this.Subject, DriversLicenseNo: val}},
    DriversLicenseState(val) {this.Subject = {...this.Subject, DriversLicenseState: val}},
    Phone1(val) {this.Subject = {...this.Subject, Phone1: val}},
    Phone1Type(val) {this.Subject = {...this.Subject, Phone1Type: val}},
    Phone2(val) {this.Subject = {...this.Subject, Phone2: val}},
    Phone2Type(val) {this.Subject = {...this.Subject, Phone2Type: val}},
    Subject() {
      if (!this.getCase.CaseID) {
        this.$emit("changeSubject", this.Subject);
      }
    },
  },
  async created() {
    this.UserType = localStorage.getItem("userType");
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillSubject(this.getSubject);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },

    fillSubject(val) {
      this.DateOfBirth = this.getCase.CaseID ? this.tryGetDate(val.DateOfBirth) : val.DateOfBirth;
      if (!this.DOBForInput) this.DOBForInput = val.DateOfBirth;
      this.SubjectID = val.SubjectID;
      this.FirstName = val.FirstName;
      this.MiddleName = val.MiddleName;
      this.LastName = val.LastName;
      this.Alias = val.Alias;
      this.SocialSecurityNo = val.SocialSecurityNo;
      this.DriversLicenseNo = val.DriversLicenseNo;
      this.DriversLicenseState = val.DriversLicenseState;
      this.Phone1 = val.Phone1;
      this.Phone1Type = val.Phone1Type;
      this.Phone2 = val.Phone2;
      this.Phone2Type = val.Phone2Type;
      this.IsPhotoVerified = val.IsPhotoVerified;
      this.PhotoSource = val.PhotoSource;
      this.Image = val.Image;
      this.RelatedImages = val.RelatedImages.filter(i => i.Image)
    },

    reviewCase() {
      this.modalShow = !this.modalShow;
      let route = this.$router.resolve('/cases/' + this.matchCaseID + '/detail');
      window.open(route.href, '_blank');
    },

    chancelCase() {
      this.modalShow = !this.modalShow
    },

    reloadData() {
      this.$emit("reloadData");
    },

    handleSocialSecurityNo() {
      if (this.SocialSecurityNo && this.SocialSecurityNo.length >= 11) {
        apiService
            .post("case/lookup/byssn", {ssn: this.SocialSecurityNo})
            .then((response) => {
              if ((response.data.length === 0) || (response.data.length === 1 && this.getCase.CaseID && response.data[0].CaseID === this.getCase.CaseID)) {
                console.log(response)
              } else {
                if (response.data.length > 1 && this.getCase.CaseID && response.data[0].CaseID === this.getCase.CaseID) {
                  this.matchCaseID = response.data[1].CaseID;
                } else {
                  this.matchCaseID = response.data[0].CaseID;
                }
                this.modalShow = true;
              }
            })
      }
    },

    isValidDateOfBirth() {
      const currentDate = new Date()
      if (!this.DateOfBirth || Number(this.DateOfBirth.substring(0, 4)) <= 1900 || new Date(this.DateOfBirth) >= currentDate) {
        this.showToast('danger', 'top-center', 4000, 'DOB must be a valid date.');
      }
    },

    uploadSubjectImage(item) {
      this.$emit("uploadSubjectImage", item);
    },

    toggleVisibilityFields(fieldName) {
      this[fieldName] = !this[fieldName]
      if (!!this[fieldName.substring(0, fieldName.length - 3)]) return

      apiService
          .get("subject/" + this.SubjectID + "/pii")
          .then(res => {
            this.fullDateOfBirth = res.data.dateOfBirth
            this.fullDriversLicenseNo = res.data.driversLicenseNo
            this.fullSocialSecurityNo = res.data.socialSecurityNo
          })
    },

    showViewAllButton() {
      if (this.RelatedImages && this.RelatedImages.length) {
        const imagesLength = this.RelatedImages.filter(i => i.Image)
        return !!imagesLength.length
      }
      return false
    },

    viewAllImages() {
      this.$bvModal.show('modal-all-images');
    },
  },
}
</script>

<style scoped>
input::-webkit-calendar-picker-indicator:not(#dob) {
  display: none;
}
.ice-icon {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 3;
}
.unmasc-ssn {
  position: absolute;
  left: 3rem;
  background: #efefef;
  top: 0.7rem;
  font-size: 1rem;
}
#modal-all-images > div {
  max-width: 45% !important;
  width: fit-content;
}
.images-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 19rem;
  overflow: auto;
}
.image-wrapper {
  width: 15rem;
  padding: 0.2rem;
  position: relative;
}
.link-title {
  position: absolute;
  bottom: 0.1rem;
  left: 0.5rem;
  text-shadow: 1px 1px 1px white;
}
.date-field {
  text-shadow: 1px 1px 1px white;
  font-size: 1rem;
  position: absolute;
  bottom: 1.3rem;
  left: 0.5rem
}
.img-block {
  display: block;
  max-width: 14.6rem;
  max-height: 9rem;
  margin: auto;
}
.verified {
  position: absolute;
  top: 0.2rem;
  right: 0rem;
}
.verified:hover .tooltip-source {
  display: block;
}
.tooltip-source {
  display: none;
  position: absolute;
  top: 2rem;
  color: #156f31;
  width: max-content;
  font-size: 1rem;
  right: 1rem;
  text-shadow: 1px 1px 1px white;
}
#modal-all-images > div {
  max-width: 45% !important;
  width: fit-content;
}
</style>